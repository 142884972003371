<template>
    <svg
        class="h-auto fill-current text-theme-green"
        width="26"
        height="27"
        viewBox="0 0 26 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 0.5C10.4288 0.5 7.91543 1.26244 5.77759 2.6909C3.63975 4.11935 1.97351 6.14968 0.989572 8.52512C0.0056327 10.9006 -0.251811 13.5144 0.249797 16.0362C0.751405 18.5579 1.98953 20.8743 3.80762 22.6924C5.6257 24.5105 7.94208 25.7486 10.4638 26.2502C12.9856 26.7518 15.5995 26.4944 17.9749 25.5104C20.3503 24.5265 22.3807 22.8603 23.8091 20.7224C25.2376 18.5846 26 16.0712 26 13.5C25.9934 10.0542 24.6216 6.75145 22.1851 4.31491C19.7486 1.87837 16.4458 0.506607 13 0.5ZM19.1875 11.225L11.8625 18.225C11.6731 18.4031 11.4225 18.5016 11.1625 18.5C11.0354 18.5018 10.9093 18.4785 10.7913 18.4313C10.6733 18.3841 10.5658 18.314 10.475 18.225L6.81251 14.725C6.71092 14.6364 6.62831 14.5281 6.56963 14.4067C6.51095 14.2854 6.47741 14.1534 6.47104 14.0187C6.46467 13.8841 6.48559 13.7495 6.53255 13.6231C6.57951 13.4968 6.65154 13.3812 6.7443 13.2834C6.83706 13.1856 6.94865 13.1075 7.07235 13.0539C7.19605 13.0003 7.32931 12.9723 7.46412 12.9715C7.59893 12.9707 7.7325 12.9972 7.85682 13.0493C7.98113 13.1015 8.09361 13.1783 8.18751 13.275L11.1625 16.1125L17.8125 9.775C18.0069 9.60536 18.2594 9.51745 18.5172 9.52964C18.7749 9.54184 19.018 9.6532 19.1955 9.84044C19.3731 10.0277 19.4714 10.2763 19.4699 10.5343C19.4684 10.7924 19.3672 11.0398 19.1875 11.225Z"
        />
    </svg>
</template>
